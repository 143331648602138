<template>
  <div id="app">
    <MySelection />
  </div>
</template>

<style>

</style>

<script>
  import MySelection from './containers/MySelection';

  export default {
    components: {MySelection},
    mounted() {
      this.$store.dispatch('loadData', this.$route.query)
    },
  }
</script>
