import KEYS from '@/keys';

export const matchesSomeItems = (arr1, arr2) => {
  const arr2Set = new Set(arr2);
  return arr2Set.size > 0
    ? arr1.some(el => arr2Set.has(el))
    : false;
};

export const matchesAllItems = (arr1, arr2) => {
  const arr2Set = new Set(arr2);
  return arr1.some(el => arr2Set.has(el));
};

export const isBlank = (str) => str == null || !str.length > 0

export const buildSelectedQueryAsString = (siteQueryName, selectedPeople) => {
  if (isBlank(siteQueryName) && isBlank(selectedPeople)) {
    return "";
  }

  if (isBlank(selectedPeople)) {
    return `${KEYS.SELECTED}=${siteQueryName}`;
  }

  const peoplePart = selectedPeople.map(person => person.poradie)
    .sort((a, b) => (+a) - (+b))
    .join(KEYS.SELECTED_PEOPLE_DELIMITER)

  return `${KEYS.SELECTED}=${siteQueryName}${KEYS.SELECTED_DELIMITER}${peoplePart}`
}

export const buildSelectedQueryAsRouterObject = (siteQueryName, selectedPeople) => {
  if (isBlank(siteQueryName) && isBlank(selectedPeople)) {
    return {};
  }

  if (isBlank(selectedPeople)) {
    return {
      [KEYS.SELECTED]: `${siteQueryName}`
    };
  }

  const peoplePart = selectedPeople.map(person => person.poradie)
    .sort((a, b) => (+a) - (+b))
    .join(KEYS.SELECTED_PEOPLE_DELIMITER)

  return {
    [KEYS.SELECTED]: `${siteQueryName}${KEYS.SELECTED_DELIMITER}${peoplePart}`,
  }
}
