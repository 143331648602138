<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<style>
  /*#app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }*/

</style>

<script>
  export default {
    mounted() {
      this.$store.dispatch('loadData', this.$route.query)
    },
  }
</script>
