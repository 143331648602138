<template>
  <b-container fluid="" class="padding-style-4">
    <div class="container-large">
      <div class="row">
        <div class="col-12">
          <div class="margin-bottom-3">
            <h1 class="alfa txt-c">
              <b-icon-check-circle scale="1.5"/>
              volimeludi.sk
            </h1>
          </div>

          <div class="content-width-800 margin-center">
            <div class="margin-bottom-1-5 h-margin-08 txt-c">
              <p>
                Dajte vedieť svojim priateľom, že JE KOHO VOLIŤ.
              </p>
            </div>
          </div>

          <KruzkujemeIntro />

          <div v-if="strany && oblasti && ludia">
            <b-row id="id_site">
              <b-col>
                <div class="content-width-600 margin-center">
                  <div class="margin-bottom-1-5 h-margin-08 txt-c">
                    <div class="h3 beta">
                      1. Politická strana
                    </div>
                    <p>
                      Vyber si politickú stranu
                    </p>
                  </div>
                </div>

                <div>
                  <b-list-group horizontal class="list-group-main justify-content-center">
                    <b-list-group-item
                      v-for="(site, index) in strany" :key="index"
                      :active="isSiteActive(site)"
                      @click="toggleSite(index)"
                      button
                    >
                      <div class="list-group-item-img">
                        <b-img :src="`img/strany/${site.image}`" height="100"/>
                      </div>
                    </b-list-group-item>
                  </b-list-group>
                </div>
              </b-col>
            </b-row>

            <b-row class="pb-4 gutter-lg-60 margin-top-3 padding-top-2" id="id_categories_people"
                   v-show="selectedSiteNumber">
              <b-col class="col-12 col-lg-4 col-xl-3 margin-bottom-4 margin-bottom-md-0">
                <div class="margin-bottom-1-5 h-margin-08">
                  <div class="h3 beta">
                    2. Oblasti
                  </div>
                  <p>
                    Vyber si oblasť, ktorá je pre Teba dôležitá
                  </p>
                </div>
                <div class="side-item-sticky">
                  <b-list-group class="list-group-side">
                    <b-list-group-item
                      v-for="(category, index) in oblasti" v-bind:key="index"
                      :active="isCategoryActive(index)"
                      @click="setCategory(index)"
                      button
                    > {{ category }}
                    </b-list-group-item>
                  </b-list-group>
                </div>
              </b-col>

              <b-col class="col-12 col-lg-8 col-xl-9">
                <div class="margin-bottom-2-5 h-margin-08">
                  <div class="h3 beta">
                    3. Kandidáti
                  </div>
                  <p>
                    Klikni na kandidáta a zisti o ňom viac. Ak stojí za to, odporuč ho alebo pridaj do svojho výberu. Zdieľaj svoj výber.
                  </p>
                  <div>
                    <b-form-input v-model="searchText" placeholder="Meno kandidáta"/>
                  </div>
                </div>

                <div v-if="hasCandidates">
                  <b-list-group class="row gutter-20 list-group-person">
                    <b-list-group-item class="col-12 col-sm-6 col-md-4 col-lg-4"
                                       :id="`person${person.poradie}`"
                                       v-for="(person) in filteredPeople" v-bind:key="person.poradie"
                                       :active="isPersonSelected(person)"
                                       @click="showPersonDetail(person)"
                    >
                      <b-row class="list-group-person-content no-gutter">
                        <div class="list-group-person-icon">
                          <b-icon-check-circle scale="1"/>
                        </div>
                        <b-col class="col-12">
                          <div class="list-group-vote">
                            <b-btn size="sm"
                                   @click.stop="togglePerson(person)">
                              <span v-if="isPersonSelected(person)">
                                <b-icon-x-circle scale="1"/>
                                Zrušiť
                              </span>
                              <span v-else>
                                <b-icon-check-circle scale="1"/>
                                Zakrúžkovať
                              </span>
                            </b-btn>
                          </div>
                        </b-col>
                        <b-col class="list-group-left align-self-start col-12">
                          <div class="list-group-person-img-block">
                            <b-img :src="`img/ludia/${person.image || defaultImage}`" height="140"/>
                          </div>
                          <div class="list-group-person-img list-group-person-img-mobile">
                            <b-img :src="`img/strany/${strany[person.strana].image}`" height="100"/>
                          </div>
                        </b-col>
                        <b-col class="list-group-right col-12">
                          <div class="list-group-info-block">
                            <div class="list-group-name">č.{{ person.poradie }} {{ person.meno }}</div>
                            <div class="list-group-city">{{person.mesto}}</div>
                            <!--                            <div class="list-group-badge">-->
                            <!--                              <b-badge-->
                            <!--                                class="mr-1"-->
                            <!--                                v-for="(category) in person.oblasti" v-bind:key="category"-->
                            <!--                                variant="info">{{oblasti[category]}}-->
                            <!--                              </b-badge>-->
                            <!--                            </div>-->
                            <div class="list-group-text txt-bold"
                                 v-html="person.nadpis"/>
                            <div class="list-group-text"
                                 v-if="!person.popis.startsWith(person.nadpis)"
                                 v-html="limitText(person.popis)"/>
                          </div>
                        </b-col>
                      </b-row>

                    </b-list-group-item>
                  </b-list-group>
                </div>
                <div v-else>
                  <p>
                    Strana {{this.strany[this.selectedSiteNumber].title}} nezverejnila informácie o svojich kandidátoch.
                  </p>
                </div>
              </b-col>
            </b-row>

            <b-row id="moj_vyber" v-show="selectedPeople.length > 0">
              <b-col>
                <div class="content-width-600 margin-center">
                  <div class="margin-bottom-2 h-margin-08 txt-c">
                    <div class="h3 beta">
                      4. Môj výber
                    </div>
                  </div>
                </div>
                <div>
                  <b-list-group class="row gutter-20 list-group-person justify-content-center">
                    <b-list-group-item class="col-12 col-sm-6 col-md-4 col-lg-3"
                                       :id="`person${person.poradie}`"
                                       v-for="(person) in selectedPeople" v-bind:key="person.poradie"
                                       @click="showPersonDetail(person)"
                    >
                      <b-row class="list-group-person-content no-gutter">
                        <div class="list-group-person-icon">
                          <b-icon-check-circle scale="1"/>
                        </div>
                        <b-col>
                          <div class="list-group-vote">
                            <b-btn size="sm"
                                   @click.stop="togglePerson(person)">
                              <span v-if="isPersonSelected(person)">
                                <b-icon-x-circle scale="1"/>
                                Zrušiť
                              </span>
                              <span v-else>
                                <b-icon-check-circle scale="1"/>
                                Zakrúžkovať
                              </span>
                            </b-btn>
                          </div>
                        </b-col>
                        <b-col class="list-group-left align-self-start col-12">
                          <div class="list-group-person-img-block">
                            <b-img :src="`img/ludia/${person.image || defaultImage}`" height="140"/>
                          </div>
                          <div class="list-group-person-img list-group-person-img-mobile">
                            <b-img :src="`img/strany/${strany[person.strana].image}`" height="100"/>
                          </div>
                        </b-col>
                        <b-col class="list-group-right col-12">
                          <div class="list-group-info-block">
                            <div class="list-group-name">č.{{ person.poradie }} {{ person.meno }}</div>
                            <div class="list-group-city">{{person.mesto}}</div>
                            <div class="list-group-text"
                                 v-html="person.nadpis"/>
                            <div class="list-group-text"
                                 v-if="!person.popis.startsWith(person.nadpis)"
                                 v-html="limitText(person.popis)"/>
                          </div>
                        </b-col>
                      </b-row>

                    </b-list-group-item>
                  </b-list-group>
                </div>
              </b-col>
            </b-row>
          </div>

        </div>
      </div>
    </div>

    <footer class="container">
      <div class="pt-3 mt-4 text-muted border-top text-center">
        <a class="text-muted small"
           href="/privacy">Ochrana osobných údajov</a>
      </div>
    </footer>

    <!-- Select menu -->
    <!-- toggle 'active' class -->
    <div class="select-menu active" v-show="selectedSiteNumber">
      <div class="select-menu-item select-menu-item-img">
        <div class="select-menu-content">
          <b-link v-scroll-to="'#id_site'">
            <div class="select-menu-text">Strana</div>
            <div class="select-menu-img">
              <img v-if="!!selectedSiteNumber"
                   :src="`img/strany/${strany[selectedSiteNumber].image}`">
            </div>
          </b-link>
        </div>
      </div>
      <div class="select-menu-item">
        <div class="select-menu-content">
          <b-link v-scroll-to="'#id_categories_people'">
            <div class="select-menu-text">Oblasti</div>
            <div class="select-menu-text-small" v-if="selectedCategories.length === 1">{{oblasti[selectedCategories[0]]}}</div>
            <div class="select-menu-number" v-else>{{selectedCategories.length || "Všetky"}}</div>
          </b-link>
        </div>
      </div>
      <div class="select-menu-item">
        <div class="select-menu-content">
          <b-link v-scroll-to="'#id_categories_people'">
            <div class="select-menu-text">Kandidáti</div>
            <div class="select-menu-number">{{filteredPeople.length}}</div>
          </b-link>
        </div>
      </div>
      <div class="select-menu-item">
        <div class="select-menu-content" id="tooltip-target-3">
          <b-tooltip target="tooltip-target-3"
                     triggers="hover"
                     variant="dark">
            <div class="select-menu-candidate-tooltip text-left"
                 v-for="(person, index) in selectedPeople" v-bind:key="index">
              č.{{ person.poradie }} {{ person.meno }}
            </div>
          </b-tooltip>
          <b-link v-scroll-to="'#moj_vyber'">
            <div class="select-menu-text">Môj výber</div>
            <div class="select-menu-number"
                 :class="{ 'text-warning': maxCandidatesAlert }">
              {{selectedPeople.length}}
            </div>
          </b-link>
        </div>
      </div>
      <div class="select-menu-item select-menu-item-btn">
        <div class="select-menu-content">
          <a class="select-menu-btn"
             v-if="!maxCandidatesAlert"
             @click="share()"
          >
            <b-icon-box-arrow-up-right scale="1"/>
            Zdieľaj
          </a>
          <a v-else class="select-menu-btn disabled btn-warning">
            <b-icon-box-arrow-up-right scale="1"/>
            max. 4 kandidáti
          </a>
        </div>
      </div>
    </div>

    <KandidatDialog :person="selectedPersonDetail"
                    :selectable="true"
                    :is-selected="isPersonSelected(selectedPersonDetail)"
                    :on-close="hidePersonDetail"/>

  </b-container>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import VueScrollTo from 'vue-scrollto'
import latinize from 'latinize'
import {matchesSomeItems} from '@/services/utils'
import {createFBShareLink} from '@/services/share'
import KandidatDialog from './KandidatDialog'
import {getBaseUrl} from '@/router';
import KruzkujemeIntro from './KruzkujemeIntro';

const TEXT_LIMIT_CHAR = 120

  export default {
    name: 'Kruzkujeme',
    components: {KruzkujemeIntro, KandidatDialog},
    data: function () {
      return {
        'searchText': null,
      }
    },
    computed: {
      ...mapState([
        'ludia',
        'oblasti',
        'strany',
        'defaultImage',
        'selectedSiteNumber',
        'selectedCategories',
        'selectedPeople',
        'selectedPersonDetail',
      ]),

      normalizedSearchText: function () {
        return this.searchText
          ? latinize(this.searchText.toLowerCase())
          : null
      },

      hasCandidates: function() {
        return !this.selectedSiteNumber || this.ludia[this.selectedSiteNumber]
      },

      filteredPeople: function () {
        if (!this.selectedSiteNumber) {
          return []
        }

        if (!this.ludia[this.selectedSiteNumber]) {
          return [];
        }

        const filtered = this.ludia[this.selectedSiteNumber].filter(p => {
          return this.selectedCategories.length === 0 || matchesSomeItems(this.selectedCategories, p.oblasti)
        })

        const filteredByText = this.normalizedSearchText
          ? filtered.filter(p => {
            return p.menoNormalized.indexOf(this.normalizedSearchText) !== -1
          })
          : filtered

        const sortByPriority = this.selectedCategories.length > 0;
        return sortByPriority
          ? filteredByText.sort((a, b) => b.priorita - a.priorita)
          : filteredByText
      },

      maxCandidatesAlert: function () {
        return this.selectedPeople.length > 4
      },

    },
    mounted() {
      setTimeout(function () {
        if (this.selectedSiteNumber && this.selectedPeople.length > 0) {
          VueScrollTo.scrollTo(`#moj_vyber`, 10, {easing: 'linear'})
        }
      }.bind(this), 3000);
    },
    methods: {
      ...mapActions([
        'toggleCategory',
        'togglePerson',
        'showPersonDetail',
        'hidePersonDetail',
      ]),

      setCategory: function (category) {
        this.$store.dispatch('toggleCategory', category)
        setTimeout(function () {
          VueScrollTo.scrollTo('#id_categories_people', 200, {easing: 'linear'})
        }, 500);
      },

      limitText: function (text) {
        return (text.length > TEXT_LIMIT_CHAR)
          ? `${text.substr(0, TEXT_LIMIT_CHAR)}...`
          : text
      },

      toggleSite: function (site) {
        this.$store.dispatch('selectSite', site)
        // scroll to content
        setTimeout(function () {
          VueScrollTo.scrollTo('#id_categories_people', 500, {easing: 'linear'})
        }, 500);
      },

      isSiteActive: function (site) {
        return this.selectedSiteNumber === site;
      },

      isCategoryActive: function (category) {
        return this.selectedCategories.indexOf(category) >= 0
      },

      isPersonSelected: function (person) {
        return this.selectedPeople.indexOf(person) >= 0
      },

      scrollToPerson: function (site, personNumber) {
        this.selectedSiteNumber = site;
        // scroll to content
        setTimeout(function () {
          VueScrollTo.scrollTo(`#person${personNumber}`, 500, {easing: 'linear'})
        }, 1000);
      },

      share: function () {
        VueScrollTo.scrollTo(`#moj_vyber`, 100, {easing: 'linear'})
        const locationBase = getBaseUrl()
        const hasSelectedSite = this.selectedSiteNumber != null;
        const shareUrl = `${locationBase}${hasSelectedSite ? '/detail' : ''}${window.location.search}`;

        window.open(
          createFBShareLink(shareUrl),
          '',
          'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600'
        )
      }
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
