<template>
  <b-container fluid="" class="padding-style-2">
    <div class="container-large">
      <div class="row">
        <div class="col-12">
          <div class="margin-bottom-1">
            <h1 class="alfa txt-c">
              <b-link @click="goToHome">
                <b-icon-check-circle scale="1.5"/>
                volimeludi.sk
              </b-link>
            </h1>
          </div>

          <div v-if="strany && selectedPeople.length > 0">
            <b-row>
              <b-col>
                <div class="content-width-600 margin-center">
                  <div class="margin-bottom-2 h-margin-08 txt-c">
                    <div class="h3 beta">
                      Odporúčaní kandidáti
                    </div>
                  </div>
                </div>

                <div class="content-width-600 margin-center">
                  <div class="margin-bottom-1-5 h-margin-08 txt-c">
                    <p>
                      Klikni na kandidáta pre viac informácií.
                    </p>
                  </div>
                </div>


                <div>
                  <b-list-group class="row gutter-20 list-group-person justify-content-center">
                    <b-list-group-item class="col-12 col-sm-6 col-md-4 col-lg-3"
                                       :id="`person${person.poradie}`"
                                       v-for="(person) in selectedPeople" v-bind:key="person.poradie"
                                       @click="showPersonDetail(person)"
                    >
                      <b-row class="list-group-person-content no-gutter">
                        <div class="list-group-person-icon">
                          <b-icon-check-circle scale="1"/>
                        </div>
                        <b-col class="list-group-left align-self-start col-12">
                          <div class="list-group-person-img-block">
                            <b-img :src="`img/ludia/${person.image || defaultImage}`" height="140"/>
                          </div>
                          <div class="list-group-person-img list-group-person-img-mobile">
                            <b-img :src="`img/strany/${strany[person.strana].image}`" height="100"/>
                          </div>
                        </b-col>
                        <b-col class="list-group-right col-12">
                          <div class="list-group-info-block">
                            <div class="list-group-name">č.{{ person.poradie }} {{ person.meno }}</div>
                            <div class="list-group-city">{{person.mesto}}</div>
                            <div class="list-group-text txt-bold" v-html="person.nadpis"/>
                            <div class="list-group-text"
                                 v-if="!person.popis.startsWith(person.nadpis)"
                                 v-html="limitText(person.popis)"/>
                          </div>
                        </b-col>
                      </b-row>

                    </b-list-group-item>
                  </b-list-group>
                </div>

              </b-col>
            </b-row>
          </div>


          <div class="text-center">
            <div>
              <b-button variant="outline-info"
                        @click="goToHome"
              >
                <b-icon-check-circle scale="1"/>
                Urob si VLASTNÝ výber
              </b-button>
            </div>
          </div>

          <KruzkujemeIntro />

        </div>
      </div>
    </div>

    <KandidatDialog :person="selectedPersonDetail"
                    :selectable="false"
                    :is-selected="false"
                    :on-close="hidePersonDetail"/>

  </b-container>
</template>

<script>
  import {mapActions, mapState} from 'vuex'
  import KandidatDialog from './KandidatDialog';
  import {getBaseUrl} from '@/router';
  import KruzkujemeIntro from './KruzkujemeIntro';

  const TEXT_LIMIT_CHAR = 150

  export default {
    name: 'MySelection',
    components: {KruzkujemeIntro, KandidatDialog},
    computed: {
      ...mapState([
        'strany',
        'defaultImage',
        'selectedPeople',
        'selectedPersonDetail',
      ]),

    },
    methods: {
      ...mapActions([
        'showPersonDetail',
        'hidePersonDetail',
      ]),

      limitText: function (text) {
        return (text.length > TEXT_LIMIT_CHAR)
          ? `${text.substr(0, TEXT_LIMIT_CHAR)}...`
          : text
      },

      goToHome: function () {
        window.location.href = getBaseUrl()
      }

    },
  }
</script>

<style scoped>

</style>
