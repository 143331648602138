import Vue from 'vue'
import Vuex from 'vuex'
import latinize from 'latinize';
import kruzkujeme3 from '../../public/backend/config/kruzkujeme_3.json'
import kruzkujeme5 from '../../public/backend/config/kruzkujeme_5.json'
import kruzkujeme9 from '../../public/backend/config/kruzkujeme_9.json'
import kruzkujeme12 from '../../public/backend/config/kruzkujeme_12.json'
import kruzkujeme13 from '../../public/backend/config/kruzkujeme_13.json'
import kruzkujeme15 from '../../public/backend/config/kruzkujeme_15.json'
import kruzkujeme16 from '../../public/backend/config/kruzkujeme_16.json'
import kruzkujeme17 from '../../public/backend/config/kruzkujeme_17.json'
import kruzkujeme18 from '../../public/backend/config/kruzkujeme_18.json'
import kruzkujeme21 from '../../public/backend/config/kruzkujeme_21.json'
import kruzkujeme22 from '../../public/backend/config/kruzkujeme_22.json'
import kruzkujeme23 from '../../public/backend/config/kruzkujeme_23.json'
import kruzkujeme25 from '../../public/backend/config/kruzkujeme_25.json'
import router from "../router"
import KEYS from '../keys'
import {buildSelectedQueryAsRouterObject, isBlank} from '@/services/utils';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    initialized: false,
    defaultImage: "avatar.jpg",
    strany: {
      3: {number: 3, title: "Progresívne Slovensko", image: "3_ps_logo.jpg", queryName: "ps"},
      5: {number: 5, title: "OĽaNO a priatelia", image: "5_olano_logo.svg", queryName: "olano"},
      9: {number: 9, title: "Modrí, Most - Híd", image: "9_modri_logo.png", queryName: "modri"},
      12: {number: 12, title: "Sloboda a Solidarita", image: "12_sas_logo.png", queryName: "sas"},
      13: {number: 13, title: "Sme rodina", image: "13_sme_rodina_logo.svg", queryName: "smerodina"},
      15: {number: 15, title: "SNS", image: "15_sns_logo.png", queryName: "sns"},
      16: {number: 16, title: "SMER - SD", image: "16_smer_logo.png", queryName: "smer"},
      17: {number: 17, title: "Hlas - SD", image: "17_hlas_logo.png", queryName: "hlas"},
      18: {number: 18, title: "SZÖVETSÉG - ALIANCIA", image: "18_aliancia_logo.svg", queryName: "aliancia"},
      21: {number: 21, title: "ĽS Naše Slovensko", image: "21_lsns_logo.png", queryName: "lsns"},
      22: {number: 22, title: "Demokrati", image: "22_demokrati_logo.svg", queryName: "demokrati"},
      23: {number: 23, title: "KDH", image: "23_kdh_logo.svg", queryName: "kdh"},
      25: {number: 25, title: "Republika", image: "25_republika_logo.png", queryName: "republika"},
    },
    oblasti: {
      "1": "Životné prostredie a odpady",
      "2": "Šport a zdravý životný štýl",
      "3": "Vzdelávanie, veda a výskum",
      "4": "Spravodlivosť a boj proti korupcii",
      "5": "Verejná správa, bezpečnosť a obrana",
      "6": "Bývanie a doprava",
      "7": "Práca a podnikanie",
      "8": "Zdravotníctvo a sociálny systém",
      "9": "Poľnohospodárstvo a potraviny",
      "10": "Kultúra, médiá a informácie",
    },
    ludia: {
      "3": [
        {
          "strana": "3",
          "poradie": "1",
          "oblasti": [7],
          "priorita": 1,
          "mesto": "Bratislava",
          "meno": "Richard Sulík Ing.",
          "nadpis": "predseda strany SaS",
          "popis": "predseda strany SaS",
          "image": "03_001_Sulik_a.jpg"
        },
      ],
    },
    selectedSiteNumber: null,
    selectedCategories: [],
    selectedPeople: [],
    selectedPersonDetail: null,
  },
  actions: {
    loadData({commit}, initialSelectedState) {
      commit('setData', {
        ...kruzkujeme3,
        ...kruzkujeme5,
        ...kruzkujeme9,
        ...kruzkujeme12,
        ...kruzkujeme13,
        ...kruzkujeme15,
        ...kruzkujeme16,
        ...kruzkujeme17,
        ...kruzkujeme18,
        ...kruzkujeme21,
        ...kruzkujeme22,
        ...kruzkujeme23,
        ...kruzkujeme25,
      })
      commit('initializeSelected', initialSelectedState)
    },

    selectSite({commit}, site) {
      if (this.state.selectedSiteNumber !== site) {
        commit('setSelectedPeople', [])
        commit('setSelectedSite', site)
        commit('updateUrl')
      }
    },

    toggleCategory({commit}, category) {
      commit('setCategory', category)
    },

    togglePerson({commit}, person) {
      commit('togglePerson', person)
      commit('updateUrl')
    },

    showPersonDetail({commit}, person) {
      commit('setActivePerson', person)
    },

    hidePersonDetail({commit}) {
      commit('setActivePerson', null)
    },
  },
  mutations: {
    setData(state, ludiaGroupByStrana) {
      if (state.initialized) {
        console.debug("already initialized")
        return
      }

      for (const strana in ludiaGroupByStrana) {
        // Fix data
        ludiaGroupByStrana[strana].forEach(person => {
          person.oblasti = person.oblasti
            ? person.oblasti.split(",").map(oblast => oblast.toString()) // comma separated without spaces
            : []
          // Fix import data from xls
          person.menoNormalized = latinize(person.meno.toLowerCase())
          person.nadpis = person.nadpis.replace(/\n/gi, ' ').trim()
          person.popis = person.popis.replace(/\n\n/gi, '<br/><br/>').replace(/\n/gi, '<br/>').trim()
          person.priorita = +person.priorita || 1
        })
      }
      // Set to store
      state.ludia = ludiaGroupByStrana
      state.initialized = true
    },

    updateUrl(state) {
      const routerQuery = buildSelectedQueryAsRouterObject(
        state.selectedSiteNumber && state.strany[state.selectedSiteNumber].queryName,
        state.selectedPeople
      )

      router.replace({query: routerQuery})
    },

    setSelectedSite(state, site) {
      state.selectedSiteNumber = site
    },

    setSelectedCategories(state, categories) {
      state.selectedCategories = categories
    },

    setCategory(state, category) {
      const i = state.selectedCategories.indexOf(category)
      state.selectedCategories = (i >= 0)
        ? [] // remove
        : [category] // set
    },

    toggleCategory(state, category) {
      const i = state.selectedCategories.indexOf(category)
      if (i >= 0) { // remove
        state.selectedCategories.splice(i, 1)
      } else {
        state.selectedCategories.push(category)
      }
    },

    setSelectedPeople(state, people) {
      state.selectedPeople = people
    },

    togglePerson(state, person) {
      const i = state.selectedPeople.indexOf(person)
      if (i >= 0) { // remove
        state.selectedPeople.splice(i, 1)
      } else {
        state.selectedPeople.push(person)
      }
    },

    setActivePerson(state, person) {
      state.selectedPersonDetail = person
    },

    initializeSelected(state, initialState) {
      if (state.initialized) {
        console.log('InitialState', initialState)

        const selected = initialState[KEYS.SELECTED]
        if (isBlank(selected)) return

        const [siteQueryPart, peopleQueryPart] = selected.split(KEYS.SELECTED_DELIMITER)

        const [siteNumber] = Object.keys(state.strany).filter(key => state.strany[key].queryName === siteQueryPart)
        if (siteNumber) {
          state.selectedSiteNumber = siteNumber

          if (peopleQueryPart) {
            state.selectedPeople = peopleQueryPart.split(KEYS.SELECTED_PEOPLE_DELIMITER).map(personNumber => {
              const [first] = state.ludia[siteNumber].filter(person => person.poradie == personNumber) // casting is expected
              return first
            })
          }
        }
      }
    }
  }
})
