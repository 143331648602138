<template>
  <div class="content-width-800 margin-center">
    <div class="box box-shadow margin-bottom-2">
      <div class="margin-bottom-2">
        <p>
          Strany nie sú len ich predsedovia. Sú to často ľudia, ktorí sa rozhodli ísť do politiky, lebo im na ich krajine záleží. Tu ich môžeš lepšie spoznať.
        </p>
        <p>
          Informácie pridávame postupne. Tak, ako nám ich sprístupňujú jednotlivé politické strany.
        </p>
      </div>

      <div class="margin-bottom-2">
        <p>
          <strong>1.</strong>&nbsp;Vyber si stranu a oblasť, ktorá ťa zaujíma, <strong>2.</strong>&nbsp;Klikni na kandidáta pre viac informácií, <strong>3.</strong>&nbsp;Odporuč ho alebo pridaj do výberu, <strong>4.</strong>&nbsp;Zdieľaj svoj výber.
        </p>
      </div>

      <b-row class="p-margin-0">
        <b-col>
          <p>
            <b>Choď voliť a krúžkuj. Pomôž aj Ty zmeniť Slovensko k lepšiemu</b>
          </p>
        </b-col>
      </b-row>

      <!-- Contact -->
      <div class="contact-info text-right">
        <a href="mailto:info@volimeludi.sk">info@volimeludi.sk</a>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'KruzkujemeIntro',
  }
</script>

<style scoped>

</style>
