<template>
  <div v-if="person">
    <b-modal id="bv-modal-person-detail"
             centered
             hide-header
             hide-footer
             @hidden="onClose"
             size="lg"
    >
      <span class="modal-close close-trigger"
            @click="hide">
        <b-icon-x-circle scale="1"/>
      </span>
      <div class="modal-body">
        <div class="list-group-person list-group-person-modal">
          <div class="list-group-person-item">
            <div class="list-group-right col-12 col">
              <div class="list-group-info-block">
                <div class="list-group-info-block-left">
                  <div class="list-group-person-img-detail">
                    <b-img :src="`img/ludia/${person.image || defaultImage}`"/>
                  </div>
                  <div class="list-group-name">č.{{ person.poradie }} {{ person.meno }}</div>
                  <div class="list-group-city">{{ person.mesto }}</div>
                  <div class="list-group-badge">
                    <span class="badge mr-1 badge-info"
                          v-for="(category) in person.oblasti" v-bind:key="category">
                      {{oblasti[category]}}</span>
                  </div>
                </div>

                <div class="list-group-info-block-right">
                  <div class="list-group-text txt-bold"
                       v-html="person.nadpis"/>
                  <div class="list-group-text"
                       v-html="person.popis"
                       v-if="!person.popis.startsWith(person.nadpis)"/>
                  <div class="list-group-share margin-bottom-05 text-left">
                    <a @click="sharePerson">
                      <b-icon-box-arrow-up-right scale="1"/> Odporučiť kandidáta
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="list-group-vote btn-down"
               v-show="selectable">
            <b-btn size="sm"
                   @click.stop="toggleSelectedPerson(person)">
              <span v-if="isSelected">
                <b-icon-x-circle scale="1"/>
                Zrušiť
              </span>
              <span v-else>
                <b-icon-check-circle scale="1"/>
                Zakrúžkovať
              </span>
            </b-btn>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import {getBaseUrl} from '@/router';
import {createFBShareLink} from '@/services/share';
import {buildSelectedQueryAsString} from '@/services/utils';

export default {
    name: 'KandidatDialog',
    props: {
      person: {
        strana: String,
        poradie: String,
        oblasti: Array,
        priorita: Number,
        mesto: String,
        meno: String,
        nadpis: String,
        popis: String,
        image: String,
      },
      selectable: Boolean,
      onClose: Function,
      isSelected: Boolean,
    },
    mounted() {
      if (this.person) {
        this.open()
      }
    },
    updated() {
      if (this.person) {
        this.open()
      }
    },
    computed: {
      ...mapState([
        'strany',
        'oblasti',
        'defaultImage',
      ]),
    },
    methods: {
      toggleSelectedPerson: function () {
        this.$store.dispatch('togglePerson', this.person)
        this.hide()
      },
      open: function () {
        this.$bvModal.show('bv-modal-person-detail')
      },
      hide: function () {
        this.$bvModal.hide('bv-modal-person-detail')
      },
      sharePerson: function () {
        const locationBase = getBaseUrl()
        const queryPart = buildSelectedQueryAsString(
          this.strany[this.person.strana].queryName,
          new Array(this.person)
        )
        const shareUrl = `${locationBase}/detail?${queryPart}`;

        window.open(
          createFBShareLink(shareUrl),
          '',
          'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600'
        )

        this.hide()
      }
    },
  }
</script>

<style scoped>

</style>
