import Vue from 'vue'
import VueRouter from 'vue-router'
import Kruzkujeme from '../containers/Kruzkujeme';

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    component: Kruzkujeme
  }
]

const router = new VueRouter({
  mode: 'history',
  hash: false,
  routes
})

export const getBaseUrl = () => {
  if (window.location.hostname.search("gitlab") >= 0) {
    return 'https://volby.gitlab.io/2023';
  }

  return location.protocol+'//'+location.hostname+(location.port ? ':'+location.port: '');
}

export default router
