import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import store from './store'
import router from './router'
import './plugins/bootstrap-vue'
import VueScrollTo from 'vue-scrollto'

import App from './App.vue'
import AppSelected from './AppSelected';

Vue.config.productionTip = false

Vue.use(VueScrollTo)

if (!String.prototype.startsWith) {
  String.prototype.startsWith = function(searchString, position){
    position = position || 0;
    return this.substr(position, searchString.length) === searchString;
  };
}

if (document.getElementById('app')) {
  new Vue({
    store,
    router,
    render: h => h(App),
  }).$mount('#app')
}

if (document.getElementById('app-selected')) {
  new Vue({
    store,
    router,
    render: h => h(AppSelected),
  }).$mount('#app-selected')
}
